import React, { Component } from "react"
import classNames from "classnames"
import TheaterVideo from "@components/Theater/TheaterVideo"
import DesktopSizzle from "../../src/videos/sizzle_desktop.mp4"

class SizzleVideo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false
    }
    this.vidRef = React.createRef()
  }
  componentDidMount() {
    const video = document.querySelector("video")

    video.addEventListener("loadeddata", event => {
      this.setState({ isLoaded: true })
    })
  }

  componentWillUnmount() {
    const video = document.querySelector("video")
    video.removeEventListener("loadeddata", event => {})
  }

  render() {
    let homePlayerClasses = classNames({
      hidden: this.state.hidden,
      "test-1 home-sizzle": true
    })

    return (
      <div className={homePlayerClasses} style={{ position: "relative" }}>
        {!this.state.isLoaded && (
          <div
            className="img-loading"
            style={{
              position: "absolute",
              inset: 0,
              display: this.state.isLoaded ? "hidden" : "block"
            }}></div>
        )}
        <video
          ref={this.vidRef}
          className="sizzle"
          width="100%"
          height=""
          playsInline
          loop
          muted
          autoPlay
          controls={false}>
          <source src={DesktopSizzle} type="video/mp4" />
        </video>

        {this.props.youtube && this.props.youtube !== "?" && (
          <div className="button-group tight mt-3 is-hidden-tablet">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls
              playing
              iconOnly
              sizzleButton
              buttonClass="sizzle-button-under"
              compId="sizzleTheaterButton"
              language={this.props.language}
            />
            <p className="sizzle-button-subtext px-0 white bold--500">
              WATCH & HEAR MORE
            </p>
          </div>
        )}
      </div>
    )
  }
}

export default SizzleVideo
